import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import InlineComponent from '~/components/InlineComponent';
import { ImportStatement } from '~/components/ImportStatement';
import { SecondaryButton } from '@entur/button';
import { Loader } from '@entur/loader';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [progress, setProgress] = React.useState(0)\n  return (\n    <div style={{ width: \'60%\' }}>\n      <Loader>Behandler</Loader>\n      <Loader progress={20}>Laster opp fil</Loader>\n      <Loader progress={progress}>\n        <SecondaryButton\n          onClick={() => setProgress(prev => (prev + 10) % 101)}\n        >\n          Gå videre &rarr;\n        </SecondaryButton>\n      </Loader>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      PageHeader,
      InlineComponent,
      ImportStatement,
      SecondaryButton,
      Loader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [progress, setProgress] = React.useState(0);
        return <div style={{
          width: '60%'
        }}>
        <Loader mdxType="Loader">Behandler</Loader>
        <Loader progress={20} mdxType="Loader">Laster opp fil</Loader>
        <Loader progress={progress} mdxType="Loader">
          <SecondaryButton onClick={() => setProgress(prev => (prev + 10) % 101)} mdxType="SecondaryButton">
            Gå videre &rarr;
          </SecondaryButton>
        </Loader>
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "loader"
    }}>{`Loader`}</h3>
    <ImportStatement imports="Loader" mdxType="ImportStatement" />
    <Props of={Loader} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p><strong parentName="p">{`Loader:`}</strong></p>
    <p>{`Loader er en visuell tilbakemelding som indikerer at lasting av innhold og data er i prosess. Denne komponenten brukes som en global loader for innhold som lastes inn på en hel side, innhold i en modal eller i en arbeidsflyt der neste trinn lastes inn.`}</p>
    <p>{`Brukerne våre forventer at man kan raskt utføre oppgaver eller se informasjon, men noen ganger tar det litt tid. Derfor er det viktig å vise en laste indikator, for å varsle og forsikre brukerne om at systemet fungerer og handlingene deres blir behandlet, men systemet trenger tid på å hente inn data, vise informasjon eller fullføre enn oppgave. En beskjed som beskriverer lasteelementets tilstand bør inkluderes, f.eks ‘Laster inn’, ‘Behandler’, ‘Aktiverer’, ‘Lagrer’ etc.`}</p>
    <p><strong parentName="p">{`Progressbar:`}</strong></p>
    <p>{`Progressbar viser fremdriftsstatusen til en handling eller prosess. Brukes for å gi brukerne beskjed om at en prosess er i gang, og eventuelt hvor lang tid denne prosessen tar eller har kommet. Bruk en progessbar for eksempel til: opplasting, nedlasting, importering av filer/dokumenter eller eksportere en rapport.`}</p>
    <p>{`Ved filopplastning kan du sende inn et tall til `}<inlineCode parentName="p">{`progress`}</inlineCode>{`-propen - du vil da få en ramme rundt loading-indikatoren.`}</p>
    <p>{`Hvis du ikke spesifiserer en `}<inlineCode parentName="p">{`progress`}</inlineCode>{`-prop, defaulter den til "indeterminate" - det vil si at den viser en vente-animasjon for å vise brukeren at siden er opptatt med å hente data, kalkulere priser osv.`}</p>
    <p>{`Du kan sende inn en tekstlig beskrivelse som children, som gir brukeren en indikasjon på hva som skjer. Dette bør være i størrelsesorden 1-2 ord.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      